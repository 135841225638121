import React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';
import facebook from '../images/facebook.png';
import Header from './header';
import './layout.css';
import sourcelab from '../images/sourcelab.png';
const Layout = ({children}) => {
  const data = useStaticQuery (graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="wrapper">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1000,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>
          {children}
        </main>
        <footer>
          <div className="footerWrapper">
            <div className="footer">
              <a
                href="https://www.facebook.com/Nederlandse.School.in.Sofia.Bulgarije"
                target="_blank"
                rel="noopener"
              >
                <img
                  alt="facebook"
                  src={facebook}
                  alt="facebook"
                  className="facebook"
                />
              </a>
            </div>
            <div className="footer">
              <span>
                Directeur en leerkracht <br /> Margo de Kruijf:{' '}
              </span>
              <a href="mailto:margo@nederlandseschool.bg">
                margo@nederlandseschool.bg
              </a>

            </div>
            <div className="footer">
              <span>Bestuur <br /> </span>
              <a href="mailto:bestuur@nederlandseschool.bg">
                bestuur@nederlandseschool.bg
              </a>
            </div>
          </div>
          <div className="sourcelab">
            made by
            <a href="https://sourcelab.bg/" target="_blank">
              <img
                alt="sourcelabLogo"
                className="sourcelabLogo"
                src={sourcelab}
              />
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
