import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../images/logo.png';

import home from '../images/home.jpg';

const Header = () => {
  return (
    <header
      style={{
        marginBottom: `1rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1000,
          padding: `1.45rem 1.0875rem`,
        }}
      >

        <div className="header">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <div className="location">
            <p>
              Sofia 1404
              <br />
              47 Nikola Vaptsarov Blv.
              <br />
              <a
                className="headerLink"
                href="http://stgeorgeschool.bg"
                target="_blank"
                rel="noopener"
              >
                stgeorgeschool.bg
              </a>
            </p>
          </div>
        </div>
        <div className="carousel">

          <div className="headerImg">
            <img src={home} className="home" alt="pictures" />
          </div>

        </div>
        <div className="menu">
          <div className="menuButton">
            <Link exact="true" to="/" activeClassName="is-active">
              Home
            </Link>
          </div>
          {/* <div className="menuButton">
            <Link to="/education/" activeClassName="is-active">
              Het onderwijs
            </Link>
          </div>  */}
          <div className="menuButton">
            <Link to="/lesson-methods/" activeClassName="is-active">
              Lesmethodes
            </Link>
          </div><div className="menuButton">
            <Link to="/team/" activeClassName="is-active">Team</Link>
          </div><div className="menuButton">
            <Link to="/schedule/" activeClassName="is-active">
              PRAKTISCHE INFORMATIE
            </Link>
          </div>
          <div className="menuButton">
            <Link to="/other-activities/" activeClassName="is-active">
              OVERIGE ACTIVITEITEN
            </Link>
          </div>
          {/* <div className="menuButton">
            <Link to="/sign-up/" activeClassName="is-active">Aanmelden</Link>
          </div> */}
          {/* <div className="menuButton">
            <Link to="/useful-links/" activeClassName="is-active">
              Handige links
            </Link>
          </div> */}
          <div className="menuButton">
            <Link to="/gallery/" activeClassName="is-active">
              Fotogalerij
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
